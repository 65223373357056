<template>
	<v-sheet flat>
		<navigation></navigation>
		<v-form ref="form">
			<mw-select-users
				required
				:multiple="false"
				role="participant"
				v-model="user"
                add-new-button
				label="User"
			></mw-select-users>
			<v-row>
				<v-col>
					<mw-select
						required
						:multiple="false"
						store-module="projects"
						label="Project"
                        item-text="internal_name"
						v-model="project"
					></mw-select>
				</v-col>
				<v-col>
					<v-text-field outlined
						v-model="sow"
						label="Statement of Work Reference"
					></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field
						outlined
						v-model="first_name"
						label="First Name"
					></v-text-field>
				</v-col>
				<v-col>
					<v-text-field
						outlined
						v-model="last_name"
						label="Last Name"
					></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="9">
					<v-text-field
						outlined
						v-model="email"
						label="Email"
					></v-text-field>
				</v-col>
				<v-col>
					<v-text-field
						outlined
						type="number"
						v-model="amount"
						label="Amount"
					></v-text-field>
				</v-col>
			</v-row>
			<v-btn @click="submit" color="primary">Submit</v-btn>
		</v-form>
	</v-sheet>
</template>

<script>
import MwSelect from "@c/ui/MwSelect";
import MwSelectUsers from "@c/ui/MwSelectUsers";
import Navigation from "@c/incentives/Navigation";
export default {
	name: "InsertIncentiveOnlySubmission",
	data: () => {
		return {
			// submitter: null,
			project: null,
			amount: null,
			email: null,
			first_name: null,
			last_name: null,
            sow: null,
			user: null,
		};
	},
	components: {
		MwSelect,
		MwSelectUsers,
		Navigation,
	},
	computed: {
		participant() {
			return this.$store.state.users.data[this.user];
		},
		projectDetails() {
			return this.$store.state.projects.data[this.project];
		},
	},
	watch: {
		participant(v) {
            if( !v ){
                return; 
            }
            if (!this.first_name) {
				this.first_name = v.first_name;
			}
			if (!this.last_name) {
				this.last_name = v.last_name;
			}
			if (!this.email) {
				this.email = v.email;
			}
		},
		projectDetails(v) {
            if( !v ){
                return; 
            }
			if (!this.sow) {
				this.sow = v.internal_name;
			}
		},
	},
	methods: {
		submit() {
			let doc = {
				status: "new",
				project: this.project,
				amount: this.amount,
				email: this.email,
				first_name: this.first_name,
				last_name: this.last_name,
			};
			if (this.user) {
				doc.user = this.user;
			}
			if (this.sow) {
				doc.sow = this.sow;
			}
			this.$store.dispatch("incentiveSubmissions/new", doc).then(() => {
				this.$refs.form.reset();
				this.$root.$emit("showToast", "Inserted");
			});
		},
	},
};
</script>
