<template>
	<v-card flat color="transparent" width="100%">
		<v-autocomplete
			:label="renderedLabel"
			clearable
			:multiple="multiple"
			outlined
			:items="users"
			:value="value"
			:append-outer-icon="addNewButton ? 'mdi-plus-circle': ''"
			@click:append-outer="newInNewTab"
			@input="select"
		>
		</v-autocomplete>
	</v-card>
</template>

<script type="text/javascript">
export default {
	name: "MwSelectUsers",
	props: {
		value: { type: [String, Array] }, // @doc the intial value
		role: { type: String }, // the user type
		label: { type: String }, 
		include: { type: Array, default: () => ([]) },
		multiple: { type: Boolean, default: true }, // whether to allow multiple answers
		addNewButton: {type: Boolean, default: false},
	},
	data: () => {
		return {};
	},
	computed: {
		plural() {
			return `${this.role}s`;
		},
		renderedLabel() {
			let label = this.label;
			if( !this.label ) {
				label = this.mwutils.prettyPrint(this.role, "TITLE");
				if( this.multiple) {
					label += "s"
				}
			}
			return label;
		},
		users() {
			let users = this.$store.getters.getByKey("users", "role", this.role);
			users = users.map((a) => { 
				let name = a.name; 
				if(!name){
					name = a.first_name + " " + a.last_name;
				}
				return { value: a.id, text: name } 
			});
			if( this.include.length ){
				let include = this.include;
				users = users.filter( u => include.includes(u.value));
			}
			return users;
		},
	},
	created() {
		this.$store.dispatch("users/openDBChannel");
	},
	destroyed() {
		this.$store.dispatch("users/closeDBChannel");
	},
	methods: {
		newInNewTab(){
			let r = this.$router.resolve({name: "NewUser"});
			window.open(r.href, '_blank');
		},
		select(selected) {
			// if (!selected || !selected.length) {
			// 	return;
			// }
			this.$emit("input", selected);
		},
	},
};
//
</script>
"
