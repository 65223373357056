<template>
	<secondary-navigation show-back>
        <template v-slot:main>
            <nav-item link="/incentives/all" icon="mdi-currency-gbp" text="All Incentives"></nav-item>
            <nav-item link="/incentives/incentive-only" icon="mdi-cash-sync" text="Incentives Only"></nav-item>
            <nav-item link="/incentives/new" icon="mdi-plus-circle" text="Add Incentive Only"></nav-item>
        </template>
    </secondary-navigation>
</template>

<script>
import SecondaryNavigation from "@c/navigation/Secondary";
import NavItem from "@c/navigation/Item"
export default {
    name: "IncentiveOnlyNavigation",
    components: {
        SecondaryNavigation, 
        NavItem
    }
}
</script>